<template>
  <div class="box">
    <el-tabs v-model="activeName">
      <el-tab-pane  label="全部订单" name="0">
      </el-tab-pane>
      <el-tab-pane  label="待确认" name="1">
      </el-tab-pane>
      <el-tab-pane  label="待付款" name="5">
      </el-tab-pane>
      <el-tab-pane  label="待收款" name="8">
      </el-tab-pane>
      <el-tab-pane  label="待服务" name="9">
      </el-tab-pane>
      <el-tab-pane label="待验收" name="11">
      </el-tab-pane>
     <!-- <el-tab-pane  label="待评价" name="15">
      </el-tab-pane> -->
      <el-tab-pane  label="已完成" name="20">
      </el-tab-pane>
      <el-tab-pane  label="已取消" name="99">
      </el-tab-pane>
    </el-tabs>
    <all :activeName="activeName.toString()"></all>
  </div>
</template>

<script>
import all from "./maintainTabs/all.vue";
export default {
  data() {
    return {
      activeName: "0"
    };
  },
  components: {
    all
  }
};
</script>

<style lang='scss' scoped>
::v-deep .el-tabs .el-tabs__nav-scroll {
  padding-left: 15px;
}
</style>