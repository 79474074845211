<template>
	<div>
		<div class="bigbox" v-if="list && list.length">
			<div class="serverInfo mb-20" v-for="(item, index) in list" :key="index">
				<div class="header flex">
					<div class="item col1 colText">
						<span class="fontColor">下单时间:{{ item.create_time }}</span>
						<span class="darkColor">订单编号：{{ item.orderNo }}</span>
						<!-- <img src="../../img/talking.png"/> -->
						<!-- <span class="blueColor">和我联系</span> -->
					</div>
					<div class="col4 flex jc-center ai-center cursor" v-if="['15', '20', '99'].includes(activeName)"
						@click="delet(item.id)">
						删除
					</div>
				</div>
				<div class="list flex">
					<div class="item flex col1">
						<div class="flex ai-center p-10">
							<div class="listImg">
								<img :src="photoURL + item.image" />
							</div>
							<div>
								<div class="brandTop words1">{{ item.serviceName }}</div>
								<div class="brandTop darkColor">
									{{ item.expertName }}·{{ item.city }}
								</div>
							</div>
						</div>
					</div>
					<div class="item col2">
						<!-- 订单状态 -->
						<span>{{ item.orderStatus | handleorderStatus }}</span>
					</div>
					<div class="item col3 flex flex-col jc-center ai-center">
						<span class="fs-18 blueColor">¥{{ item.actualPrice }}</span>
						<span class="pt-5" v-if="['1', '15'].includes(activeName)">对公转账·全额</span>
					</div>
					<!-- 状态1 待确认 待服务  -->
					<div class="item col4 flex jc-center ai-center"
						v-if="item.orderStatus == '1' || item.orderStatus == '9'">
						<div>
							<div class="cursor" @click="toDetails(item.orderStatus, item.id)">
								查看详情
							</div>
							<!-- <div class="cursor" @click="cancelOrder(item.id)">取消订单</div> -->
						</div>
					</div>
					<!-- 状态1 待专家确认收款  -->
					<div class="item col4 flex jc-center ai-center"
						v-if=" item.orderStatus == 8">
						<div>
							<div class="cursor" @click="toDetails(item.orderStatus, item.id)">
								查看详情
							</div>
						</div>
					</div>
					<!-- 状态5 待付款 -->
					<div class="item col4 flex flex-col jc-center ai-center" v-if="item.orderStatus == 5">
						<el-button type="primary" @click="uploadCredentials(item)">上传转账凭证</el-button>
						<span class="cursor pt-10" @click="toDetails(item.orderStatus, item.id)">
							查看详情
						</span>
						<span class="cursor pt-10" @click="cancelOrder(item.id)">取消订单</span>
					</div>
					<!-- 状态11 待验收 -->
					<div class="item col4 flex flex-col jc-center ai-center" v-if="item.orderStatus == 11">
						<el-button type="primary"  @click="uploadAcceptance(item)">验收</el-button>
						<div class="cursor mt-10" @click="toDetails(item.orderStatus, item.id)">
							查看详情
						</div>
						<!-- <div class="cursor mt-10">再次购买</div> -->
					</div>
					<!-- 状态99 已取消 -->
					<div class="item col4 flex jc-center ai-center" v-if="item.orderStatus == '99'">
						<div class="flex flex-col jc-center ai-center">
							<span class="cursor pt-10" @click="toDetails(item.orderStatus, item.id)">
								查看详情
							</span>
							<!-- <span class="cursor pt-10"
              ><router-link to="/diagnosisDetails">再次购买</router-link></span
            > -->
						</div>
					</div>
					<!-- 状态20 已完成 -->
					<div class="item col4 flex jc-center ai-center" v-if="item.orderStatus == 20">
						<div class="flex flex-col jc-center ai-center">
							<span class="cursor pt-10" @click="toDetails(item.orderStatus, item.id)">
								查看详情
							</span>
							<!-- <span class="cursor pt-10"
              ><router-link to="/diagnosisDetails">再次购买</router-link></span
            > -->
							<!-- <span class="cursor pt-10">
								<router-link to="/diagnosisDetails">申请售后</router-link>
							</span> -->
						</div>
					</div>
					<!-- 状态15 待评价 -->
					<div class="item col4 flex jc-center ai-center" v-if="item.orderStatus == 15">
						<div class="flex flex-col jc-center ai-center">
							<span class="cursor pt-10" @click="toDetails(item.orderStatus, item.id)">
								查看详情
							</span>
							<!-- <span class="cursor pt-10"
              ><router-link to="/diagnosisDetails">再次购买</router-link></span
            > -->
						</div>
					</div>
				</div>
			</div>
			<!-- 弹窗 -->
			<cancelorderDia ref="cancelorderDia" :orderId="Id" @cancelSubimt1="cancelSubimt1"></cancelorderDia>
			<!-- 上传转账凭证弹窗 -->
			<el-dialog title="上传凭证" :visible.sync="credentialsDia" width="30%">
				<div class="top">
					<span class="biaoti1">对公转账</span>
					<ul>
						<li><span>户名：</span>{{ bandUserName }}</li>
						<li><span>开户行:</span>{{ bandDeposit }}</li>
						<li><span>账号:</span>{{ bandDeposit }}</li>
					</ul>
				</div>
				<div class="flex">
					<!-- 上传组件 -->
					<el-upload class="avatar-uploader mr-10" :action="baseURL + '/base-api/file/upload'"
						:show-file-list="false" :on-success="handleAvatarSuccess" :on-change="handleChange">
						<img v-if="submitForm.certImage" :src="submitForm.certImage" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<!-- <div>
						<el-select v-model="submitForm.bankName" placeholder="请选择银行卡">
							<el-option v-for="item in bankList" :key="item.index" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
						<el-input v-model="submitForm.bankAccountNo" placeholder="请输入内容"></el-input>
					</div> -->
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="credentialsDia = false">取 消</el-button>
					<el-button type="primary" @click="submit">提交审核</el-button>
				</span>
			</el-dialog>
			<!-- 验收弹窗 -->
			<el-dialog title="查看报告" :visible.sync="acceptance" width="50%">
				<div class="top">
					<span class="biaoti1">报告内容</span>
					<ul>
						<li><span>标题：</span>{{ Acceptance.title }}</li>
						<li><span>接收企业：</span>{{ Acceptance.entName }}</li>
						<li><span>备注：</span>{{ Acceptance.remarks }}</li>
					</ul>
				</div>
				<div class="top">
					<span class="biaoti1">报告附件</span>
					<ul>
						<li v-for="item in Acceptance.attachmentList" :key="item.id">{{dispose(item)}} <span class="download" @click="download(item)">查看/下载</span></li>
					</ul>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="acceptance = false">取 消</el-button>
					<el-button type="primary" @click="submitAcceptance()">立即验收</el-button>
				</span>
			</el-dialog>
			<!-- 分页 -->
			<div class="globalpPagination">
				<el-pagination hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="current" :page-sizes="[5, 10, 15, 20]" :page-size="size"
					layout=" prev, pager, next,sizes, jumper" :total="total"></el-pagination>
			</div>
		</div>
		<div class="empty flex jc-center ai-center" v-else>
			<div class="flex flex-col jc-center ai-center">
				<img src="../../img/statusEmpty.png" />
				<span>暂无相关内容</span>
			</div>
		</div>
	</div>
</template>

<script>
	import cancelorderDia from "../common/cancelorderDia";
	export default {
		data() {
			return {
				Id: "",
				submitForm: {
					bankAccountNo: "",
					bankName: "",
					certImage: "", //转账凭证 图片
					orderId: "",
				},
				bankList: [],
				fileList: [],
				credentialsDia: false,
				acceptance: false, //填写转账凭证弹出层
				total: 10,
				current: 1,
				size: 5,
				list: [],
				bandUserName: "", //户名
				bandDeposit: "", //开户行
				bandAccount: "", //账号
				Acceptance:[] //报告
			};
		},
		props: {
			activeName: {
				type: String,
				required: true,
			},
		},
		watch: {
			activeName(newVal) {
				this.getList(newVal);
			},
		},
		methods: {
			cancelSubimt1() {
				this.getList(1);
			},
			getList(orderStatus) {
				const form = {
					channel: 2,
					current: this.current,
					size: this.size,
					orderStatus: +orderStatus,
					skillType: 2,
				};
				this.$get("/shop-api/mall/expert-order/list", form).then((data) => {
					this.list = data.data.records;
					this.total = data.data.total;
				});
			},
			handleSizeChange(val) {
				this.size = val;
				this.getList(this.activeName);
			},
			handleCurrentChange(val) {
				this.current = val;
				this.getList(this.activeName);
			},
			// 取消订单弹框
			cancelOrder(id) {
				this.$refs.cancelorderDia.cancelOrderDiaVisible = true;
				this.Id = id;
			},
			// 删除
			delet(id) {
				this.$post("/shop-api/mall/expert-order/delete", {
					id
				}).then((data) => {
					if (data.code == 1000) {
						this.$message.success("删除成功");
						this.getList();
					} else {
						this.$message.info(data.msg);
					}
				});
			},
			handleAvatarSuccess(file) {
				this.submitForm.certImage = this.photoURL + file.data;
			},
			handleChange(file) {
				this.fileList.push(file.uid);
			},
			// 上传凭证弹窗
			uploadCredentials(item) {
				this.submitForm.orderId = item.id;
				this.bandUserName = item.bandUserName;
				this.bandDeposit = item.bandDeposit;
				this.bandAccount = item.bandAccount;
				this.credentialsDia = true;
				this.$get("/shop-api/mall/expert-order/bank/list").then((data) => {
					this.bankList = data.data;
				});
			},
			//查看报告弹窗
			uploadAcceptance(item){
				this.acceptance = true;
				this.acceptanceId = item.id;
				this.$get("/shop-api/mall/expert-order/report/get?id="+item.id).then((data) => {
					if (data.code != 1000) {
						this.$message.info(data.msg);
						return
					}
					this.Acceptance = data.data
				});
			},
			dispose(e) {
				let arr = e.split('/')
				return arr[arr.length - 1]
			},
			//下载
			download(item){
				window.open(this.photoURL+item)
			},
			submitAcceptance(){
				this.$post('/shop-api/mall/expert-order/receive?id='+this.acceptanceId).then(data=>{
					if (data.code != 1000) {
						this.$message.info(data.msg)
						return;
					}
					this.acceptance = false;
					this.$message.success("验收成功");
					this.getList(this.activeName);
				})
			},
			// 提交审核
			submit() {
				this.$postJson("/shop-api/mall/expert-order/pay", this.submitForm).then(
					(data) => {
						if (data.code == 1000) {
							this.$message.success("提交成功");
							this.credentialsDia = false;
							this.submitForm = {};
							this.getList(this.activeName);
						} else {
							this.$message.info(data.msg);
						}
					}
				);
			},
			toDetails(status, id) {
				this.$router.push({
					path: "/orderDetail",
					query: {
						id
					},
				});
			},
		},
		filters: {
			//   处理订单状态
			handleorderStatus: function(val) {
				switch (val) {
					case 1:
						return "待确认";
					case 5:
						return "待付款";
					case 8:
						return "待服务";
					case 11:
						return "待验收";
					case 15:
						return "待评价";
					case 20:
						return "已完成";
					case 99:
						return "已取消";
				}
			},
		},
		components: {
			cancelorderDia,
		},
		mounted() {
			this.getList("0");
		},
	};
</script>
<style lang="scss" scoped>
	* {
		.bigbox {
			width: 98%;
			margin: 0 auto;

			.serverInfo {
				.col1 {
					flex: 1;

					img {
						width: 16px;
						height: 16px;
					}

					span:nth-child(2) {
						padding-left: 15px;
					}

					span:nth-child(3) {
						padding-left: 300px;
					}
				}

				.col2 {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 104px;

					div {
						height: 2px;
					}
				}

				.col3 {
					width: 120px;
					text-align: center;

					div {
						height: 20px;
					}
				}

				.col4 {
					width: 320px;

					div {
						height: 20px;
					}
				}

				.colText {
					text-align: left !important;
					padding-left: 15px;
				}

				.header {
					border: 1px solid #bae7ff;
					background-color: #e6f7ff;
					display: flex;
					color: #595959;

					.item {
						text-align: center;
						line-height: 54px;
						height: 54px;
					}
				}

				.list {
					display: flex;

					.item {
						height: 148px;
						border-right: 1px solid #dddddd;
						border-bottom: 1px solid #dddddd;

						&:first-child {
							border-left: 1px solid #dddddd;
						}

						.listImg {
							width: 100px;
							height: 100px;
							margin-right: 15px;

							img {
								border: 1px solid #dddddd;
								border-radius: 10px;
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.brandTop {
							height: 30px;
							text-align: left;
							line-height: 30px;
						}
					}
				}
			}

			.empty {
				height: 700px;
			}
		}

		.empty {
			color: #8c8c8c;
			min-height: 700px;
		}

		.words1 {
			font-size: 16px;
			font-weight: 600;
			padding-right: 20px;
			color: #262626;
		}

		.top {
			margin-bottom: 22px;

			ul {
				li {
					margin-top: 10px;
					list-style: none;
				}
			}
		}
	}
	.download{
		padding:10px 20px;
		color: #409EFF;
		cursor:pointer;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
	}

	::v-deep .el-dialog .el-dialog__header {
		background-color: #f8f8f8;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 88px;
		height: 88px;
		line-height: 88px;
		text-align: center;
	}

	.avatar {
		width: 88px;
		height: 88px;
		display: block;
	}

	.el-select {
		margin-bottom: 10px;
		width: 100%;
	}
</style>
